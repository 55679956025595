/* @media (max-width: 1750px){
    .gallery-assets li{
        width:calc(25% - 19.2px);
    }
    .album-set-icon a {
        height: 315px;
    }
} */
@media (min-width: 1200px){
    .analystic-widgets h2,
    .google-reviews .h2{
        font-size: 36px;
    }
}
@media (min-width: 1024px){
    .analystic-widgets .sidebar-cols{
        max-width: 332.5px;
        width: 100%;
        margin-top: 0;
    }
    .analystic-widgets .left-sidebar-cols{
        max-width: calc(100% - 332.5px);
    }
}
@media (max-width: 1750px){
    .analystic-widgets .widget-card{
        padding: 20px 15px;
    }
}
@media (max-width: 1600px){
    .analystic-widgets .card-top-head{
        min-height: 48px;
    }
}
@media (max-width: 1399px){
    .analystic-table.trfc-ratio{
        aspect-ratio: initial;
        height: auto !important;
    }
}
@media (max-width: 1360px){
    .sidebar-wrapper{
        width: 70px;
        z-index: 999;
    }
    .sidebar-wrapper:hover{
        width: 220px;
    }
    .sidebar-wrapper:hover .sidebar-list .sidebar-link .menu-name{
        display: block !important;
    }
    .sidebar-wrapper:hover .sidebar-menu-list .sidebar-submenu {
        position: relative;
        left: 0;
        min-width: 100%;
        z-index: inherit;
    }
    .sidebar-wrapper.close_icon{
        width: 220px;
    }
    .page-header{
        width: calc(100% - 70px);
        padding: 18px 20px;
    }
    .sidebar-menu-list{
        height: calc(100vh - 74px);
    }
    .page-header.close_icon {
        width: calc(100% - 220px);
    }
    .sidebar-list .sidebar-link .menu-name{
        display: none !important;
    }
    .sidebar-menu-list .main-submenu a .submenu-name{
        padding-left: 5px;
    }
    /* .sidebar-list .sidebar-link .menu-icon{
        width: 70px;
    } */
    .sidebar-wrapper.close_icon .sidebar-list .sidebar-link .menu-icon{
        width: 35px;
    }
    .sidebar-wrapper.close_icon .sidebar-link .menu-name{
        display: flex !important;
        width: calc(100% - 35px);
        opacity: 1;
        transform: scale(1);
    }
    .sidebar-menu-list .sidebar-submenu{
        position: absolute;
        top: 0;
        left: 100%;
        min-width: 220px;
        border-radius: 0 5px 5px 0;
    }
    .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-menu-list .sidebar-submenu{
        position: relative;
        z-index: 1;
        left: 0;
    }
    .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-menu-list .main-submenu a .submenu-name{
        padding-left: 25px;
    }
    .page-body{
        padding: 25px 20px;
        margin-left: 70px;
    }
    .page-body-wrapper .sidebar-wrapper.close_icon ~ .page-body{
        margin-left: 220px;
    }
    .logo-wrapper p{
        display: none;
    }
    .header-search{
        display: none;
    }
    .page-header .toggle-sidebar{
        left: 0;
    }
    .page-header .left-header svg{
        transform: rotate(180deg);
    }
    .dashboard-action-strip::after{
        width: calc(100% + 40px);
        left: -20px;
    }
    .dashboard-overlay-placeholder {
        left: 70px;
        width: calc(100% - 70px);
        top: 88px;
      }
      .uploaded-product-media .property-img {
        width: calc(100%/8 - 18px);
      }
      .uploaded-property-img .hero-image {
        min-width: 170px;
      }
}

@media (max-width: 1199px){
    .gallery-assets li{
        width: calc(33.333% - 19.2px);
    }
    .select-bed-col {
        width: calc(100% - 104px);
    }
    .specification-dropdown .booking-form-input{
        width: 50%;
    }
    .dashboards-nav-list li{
        width: calc(100%/3 - 16px);
    }
    .page-body-wrapper{
        margin-top: 82px;
    }
    .analystic-widgets .card-top-head{
        min-height: initial;
    }
    .uploaded-product-media .property-img {
        width: calc(100%/6 - 18px);
    }
}

@media (max-width: 991px){
    .add-remove-btn{
        margin-top: 0rem;
    }
    .gallery-assets li {
        width: calc(50% - 19.2px);
    }
    .add-more-blocks{
        width: 65px;
    }
    .select-bed-col {
        width: calc(100% - 75px);
    }
    .select-bed-col:nth-child(2n+1){
        margin-right: 10px;
    }
    .uploaded-property-img{
        flex-wrap: wrap;
    }
    .uploaded-property-img .uploaded-img-view {
        width: calc(33.333% - 14px) !important;
        height: auto !important;
    }
    .uploaded-property-img .property-img:not(.uploaded){
        padding: 0 50px;
    }
    .left-tabs .nav .nav-link{
        padding: 15px 30px 15px 20px;
    }
    .page-body {
        padding: 25px 10px;
    }
    .dashboards-nav-list li{
        width: calc(100%/2 - 16px);
    }   
    .filter-btns-group{
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .filter-btns-group .btn-outline{
        margin: 3px 0;
    }
    .filter-btns-group::after{
        width: 100%;
        height: 1px;
        top: initial;
        bottom: 0;
    }
    .custom-filter{
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .uploaded-product-media .property-img {
        width: calc(100%/4 - 18px);
      }
      .uploaded-property-img .hero-image {
        min-width: 200px;
      }
}

@media (max-width: 767px){
    .sidebar-wrapper{
        transform: translateX(-90px);
    }
    .sidebar-wrapper.close_icon{
        transform: translateX(0);
        width: 70px;
    }
    .sidebar-wrapper.close_icon .sidebar-list .sidebar-link .menu-icon{
        width: 70px;
    }
    .sidebar-wrapper.close_icon .sidebar-link .menu-name{
        display: none !important;
    }
    .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-menu-list .sidebar-submenu{
        position: absolute;
        left: 100%;
    }
    .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-menu-list .main-submenu a .submenu-name{
        padding-left: 5px;
    }
    .page-header{
        width: 100%;
        padding: 10px 18px;
    }
    .page-header.close_icon{
        width: calc(100% - 70px);
    }
    .page-body{
        margin-left: 0;
        padding: 15px 5px;
        min-height: calc(100vh - 66px);
    }
    .page-body-wrapper .sidebar-wrapper.close_icon ~ .page-body{
        margin-left: 70px;
    }
    .login-wrapper{
        padding: 0 15px;
    }
    .page-body-wrapper{
        margin-top: 60px;
    }
    .atmosphere-username{
        display: none;
    }
    .switch-now,
    .switch-now .switchweb{
        font-size: 14.5px;
    }
    header .left-header{
        width: 45px;
        align-items: center;
    }
    .switch-now .switchweb{
        white-space: nowrap;
    }
    .atmosphere-logged-user{
        padding-left: 10px;
    }
    .atmosphere-logged-user .atmosphere-account::after{
        margin-left: 7px;
    }
    .page-header .toggle-sidebar{
        width: 35px;
        height: 35px;
    }
    .page-header .toggle-sidebar img{
        max-width: 20px;
        vertical-align: sub;
    }
    .switch-back a{
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .atmosphere-pagination{
        width: 100%;
        margin-top: 15px;
    }
    .show-enteries{
        display: flex;
        width: 100%;
    }
    .show-enteries,
    .atmosphere-pagination,
    .atmosphere-pagination .pagi-wrap,
    .atmosphere-pagination .pagination{
        justify-content: center !important;
    }
    .atmosphere-pagination .pagination-info{
        padding-right: 0 !important;
    }
    .atmosphere-pagination .pagination{
        margin-top: 10px !important;
    }
    .pagination-info{
        width: 100%;
        text-align: center;
    }
    .uploaded-property-img .uploaded-img-view {
        width: calc(50% - 14px) !important;
    }
    .left-tabs .nav .nav-link{
        padding: 14px 20px 14px 15px;
    }
    .dashboards-nav-list li{
        width: 100%;
    }
    .welcome-wrap{
        margin-top: 22px;
    }
    .dashboard-overlay-placeholder {
        left: 0px;
        width: calc(100% - 0px);
        top: 75px;
      }
      .uploaded-product-media .property-img {
        width: calc(100%/4 - 18px);
      }
      .uploaded-property-img .hero-image {
        min-width: 270px;
      }
}

@media (max-width: 575px){
    .gallery-assets li {
        width: calc(100%);
    }
    .album-set-icon a{
        height: auto;
    }
    .specification-dropdown{
        flex-wrap: wrap;
    }
    .specification-dropdown .booking-form-input{
        width: 100%;
    }
    .specification-dropdown > span{
        text-align: center;
        width: 100%;
        margin: 10px 0;
    }
}

@media (max-width: 480px){
    .uploaded-property-img .uploaded-img-view {
        width: 100% !important;
    }
    .uploaded-property-img .property-img:not(.uploaded){
        width: 100%;
        padding: 25px 20px;
    }
    .amenity-wrap{
        flex-wrap: wrap;
    }
    .amenity-view{
        width: calc(100% - 30px);
    }
    .amenity-wrap .btn{
        margin-top: 18px;
    }
    .uploaded-product-media .property-img {
        width: calc(100%/2 - 18px);
      }
      .uploaded-property-img .hero-image {
        min-width: 100%;
      }
}